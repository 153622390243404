import React, { useState } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { register } from "../../redux/actions/auth";
import { saveMe } from "../../redux/features/userSlice";
import useScrollToTop from "../../hooks/useScrollToTop";
import Alert from "../../components/Alert/Alert";
import { Form, Spinner } from "react-bootstrap";
import { useGetSubjectsQuery } from "../../redux/features/courseSlice";

function Signup() {
  useScrollToTop();

  const { data: subjects } = useGetSubjectsQuery();

  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isAuthenticated, loading, error } = useSelector(
    (state) => state.userState
  );

  const [rememberMe, setRememberMe] = useState(true);
  const [formData, setFormData] = useState({
    name: "",
    address: "",
    email: "",
    password: "",
    phone_number: "",
    subject_id: "",
  });
  const { name, address, email, password, phone_number, subject_id } = formData;

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = (e) => {
    e.preventDefault();

    // const data = { name:name.trim(), address:address.trim(), email:email.trim(), password:password.trim(),organization_name:organization_name.trim(), user_type:type }
    // console.log(formData)

    dispatch(saveMe(rememberMe));
    dispatch(
      register({
        name: name.trim(),
        address: address.trim(),
        email: email.trim(),
        password: password.trim(),
        phone_number,
        subject_id,
      })
    );
  };

  if (isAuthenticated) return <Navigate to={"/instructor-records"} />;
  return (
    <section class="p-0 d-flex align-items-center position-relative overflow-hidden">
      <div class="container-fluid">
        <div class="row">
          {/* <!-- Right --> */}
          <div class="col-12 col-lg-6 m-auto">
            <div class="row my-5">
              <div class="col-sm-10 col-xl-8 m-auto">
                {/* <!-- Title --> */}
                <img
                  src="assets/images/element/03.svg"
                  class="h-40px mb-2 d-block d-sm-none"
                  alt=""
                />
                <h2>إنشاء حساب جديد!</h2>
                <p class="lead mb-4">
                  سعيدين بتواجدك معنا, املأ الحقول التالية لإنشاء حساب.
                </p>
                {error?.message ? (
                  <div class="mb-4">
                    {" "}
                    <Alert text={error?.message} type="error" />{" "}
                  </div>
                ) : null}
                {/* <!-- Form START --> */}
                <Form>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>اسم المدرس</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="اكتب اسم المدرس هنا"
                      autoFocus
                      className="border-0 bg-light rounded-end ps-1"
                      name="name"
                      onChange={onChange}
                      value={name}
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label>رقم الجوال</Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="77032155"
                      className="border-0 bg-light rounded-end ps-1"
                      name="phone_number"
                      onChange={onChange}
                      value={phone_number}
                    />
                  </Form.Group>

                  <Form.Select
                    className="mb-3  mt-4"
                    name="subject_id"
                    onChange={onChange}
                    value={subject_id}
                  >
                    <option value="" disabled>
                      اختر تخصص
                    </option>
                    {subjects?.map((sb) => (
                      <option key={sb.id} value={sb.id}>
                        {sb.name}
                      </option>
                    ))}
                  </Form.Select>

                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>العنوان</Form.Label>
                    <Form.Control
                      type="text"
                      className="border-0 bg-light rounded-end ps-1"
                      name="address"
                      onChange={onChange}
                      value={address}
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>الايميل</Form.Label>
                    <Form.Control
                      type="text"
                      className="border-0 bg-light rounded-end ps-1"
                      name="email"
                      onChange={onChange}
                      value={email}
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlInput1"
                  >
                    <Form.Label>كلمة المرور</Form.Label>
                    <Form.Control
                      type="password"
                      className="border-0 bg-light rounded-end ps-1"
                      name="password"
                      onChange={onChange}
                      value={password}
                    />
                  </Form.Group>
                </Form>

                {/* <!-- Button --> */}
                <div class="align-items-center mt-6">
                  <div class="d-grid">
                    {loading ? (
                      <button
                        class="btn btn-primary-soft mb-0"
                        type="button"
                        disabled
                      >
                        <span
                          class="spinner-border spinner-border-sm me-2"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        يتم التحميل...
                      </button>
                    ) : (
                      <button
                        onClick={onSubmit}
                        class="btn btn-primary mb-0"
                        type="button"
                      >
                        تسجيل
                      </button>
                    )}
                  </div>
                </div>
                {/* <!-- Form END --> */}

                {/* <!-- Sign up link --> */}
                <div class="mt-4 text-center">
                  <span>
                    لديك حساب؟<Link to="/"> سجل دخول هنا</Link>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Signup;
