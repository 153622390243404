import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom'
// import  logo  from '../../assets/images/mine/sareyat-logo.png'
import  logo  from '../../assets/images/mine/logo_light.png'

function Sidebar() {

  const dispatch = useDispatch();
	const { user } = useSelector(state=>state.userState)
  
  return (
    <nav class="navbar sidebar navbar-expand-xl navbar-dark bg-dark" style={{"overflow-y": "auto"}}>

	{/* <!-- Navbar brand for xl START --> */}
	<div class="d-flex align-items-center">
		<Link to='/' class="navbar-brand">
			<img class="navbar-brand-item" src={logo} alt=""/>
		</Link>
	</div>
	{/* <!-- Navbar brand for xl END --> */}
	
	<div class="offcanvas offcanvas-start flex-row"  data-bs-backdrop="true" tabindex="-1" id="offcanvasSidebar">
		<div class="offcanvas-body sidebar-content d-flex flex-column bg-dark">

			{/* <!-- Sidebar menu START --> */}
			<ul class="navbar-nav flex-column" id="navbar-sidebar">
				
				{/* <!-- Menu item 1 --> */}
				<li data-bs-dismiss="offcanvas" class="nav-item"><NavLink to='/' className="nav-link" end><i class="bi bi-house fa-fw me-2"></i>الرئيسية</NavLink></li>
				
				{user?.is_admin ?
				<>
				<li class="nav-item ms-2 my-2">الأقسام</li>

				

				<li data-bs-dismiss="offcanvas" class="nav-item"> <NavLink to='/instructors' className="nav-link"><i class="fas fa-user-tie fa-fw me-2"></i>المدرسين</NavLink></li>
				</>
				: null}
				

				


			</ul>
			{/* <!-- Sidebar menu end --> */}

			{/* <!-- Sidebar footer START --> */}
			<div class="px-3 mt-auto pt-3">
				<div class="d-flex align-items-center justify-content-between text-primary-hover">
						<Link to='/' class="h5 mb-0 text-body" href="index.html" data-bs-toggle="tooltip" data-bs-placement="top" title="Home">
							<i class="bi bi-globe"></i>
						</Link>
						<span onClick={()=> {}} role='button' class="h5 mb-0 text-body" data-bs-toggle="tooltip" data-bs-placement="top" title="Sign out">
							<i class="bi bi-power"></i>
						</span>
				</div>
			</div>
			{/* <!-- Sidebar footer END --> */}
			
		</div>
	</div>
</nav>
  )
}

export default Sidebar