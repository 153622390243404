import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import "bootstrap/dist/css/bootstrap.rtl.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap-icons/font/bootstrap-icons.css";
import './styles/style-rtl.css';
import './styles/main.scss';
import { Provider } from 'react-redux'
import store from './redux/store';
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from 'react-bootstrap';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
      <ThemeProvider dir="rtl">
        <App />
      </ThemeProvider>
      </Router>
   </Provider>
  </React.StrictMode>
);