import React, { Suspense } from 'react'
import { Navigate, Outlet, useNavigate } from 'react-router-dom'
import Sidebar from './Sidebar'
import Topbar from './Topbar'
import GroupSpinner from '../../components/Spinner/GroupSpinner';
import ErrorFallback from '../../components/ErrorFallback/ErrorFallback';
import { ErrorBoundary } from 'react-error-boundary';
import { useSelector } from 'react-redux';

function Dashboard() {
  const navigate = useNavigate()
  const {user} = useSelector(state=>state.userState)

  return (
    user?.is_admin ? 
    <div>
      <Sidebar/>
      <div class="page-content">
        <Topbar/>
        <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onReset={() => navigate('/')}
            resetKeys={[]}
            >
            <Suspense fallback={<GroupSpinner />}>
                <Outlet />
            </Suspense>
        </ErrorBoundary>
      </div>
    </div>
    :
    <Navigate to='signin' replace/>

  )
}

export default Dashboard